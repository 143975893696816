<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="작성부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 작성일(LBLREGDATE) -> 작성기간으로 변경 검토필요 -->
          <c-datepicker
            :range="true"
            defaultStart="-2M"
            defaultEnd="0M"
            label="작성기간"
            name="dailyResultDts"
            v-model="searchParam.dailyResultDts"
          />
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="grid"
      title="대기일지 목록"
      tableId="grid"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-manage',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'dailyResultDt',
            field: 'dailyResultDt',
            label: 'LBLREGDATE',
            align: 'center',
            sortable: true,
            type: 'link'
          },
          {
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: '작성상태',
            align: 'center',
            sortable: true,
          },
          {
            name: 'weatherName',
            field: 'weatherName',
            label: '날씨',
            align: 'center',
            sortable: true,
          },
          {
            name: 'temperature',
            field: 'temperature',
            label: '온도(℃)',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLWRITEDEPT',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLREGISTER',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        dailyResultDts: [],
        plantCd: null,
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        isFull: true,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.env.air.daily.result.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.detailPopup(row);
    },
    detailPopup(result) {
      this.popupOptions.target = () => import(`${"./dailyAirReportManageDetail.vue"}`);
      this.popupOptions.title = '대기일지 상세';
      this.popupOptions.param = {
        envAirDailyResultId: result ? result.envAirDailyResultId : '',
        plantCd: result ? result.plantCd : '',
        isFullScreen: false,
        approvalStatusCd: result ? result.approvalStatusCd : null,
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
